import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { getWeatherIcon } from './utils/getWeatherIcon';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';

import './Previsions.css';

// Icônes jour
const ClearIcon = `${process.env.PUBLIC_URL}/assets/icons/day/clear.svg`;
const Cloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_1.svg`;
const Cloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_2.svg`;
const Cloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_3.svg`;
const Cloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_4.svg`;
const Cloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_5.svg`;
const Cloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_6.svg`;
const Cirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_1.svg`;
const Cirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_2.svg`;
const Cirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_3.svg`;
const Cirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_4.svg`;
const Rain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_1.svg`;
const Rain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_2.svg`;
const Rain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_3.svg`;
const Rain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_4.svg`;
const RainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_1.svg`;
const RainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_2.svg`;
const RainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_3.svg`;
const RainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_4.svg`;
const RainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_1.svg`;
const RainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_2.svg`;
const RainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_3.svg`;
const RainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_1.svg`;
const RainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_2.svg`;
const RainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_3.svg`;
const RainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_4.svg`;
const IceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_1.svg`;
const IceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_2.svg`;
const IceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_3.svg`;
const IceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_4.svg`;
const Snow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_1.svg`;
const Snow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_2.svg`;
const Snow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_3.svg`;
const Snow4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_4.svg`;
const SnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_1.svg`;
const SnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_2.svg`;
const SnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_3.svg`;
const SnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_4.svg`;
const Storm1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_1.svg`;
const Storm2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_2.svg`;
const Storm3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_3.svg`;
const StormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_1.svg`;
const StormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_2.svg`;
const StormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_3.svg`;
const StormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_4.svg`;

// Icônes nuit
const NightClearIcon = `${process.env.PUBLIC_URL}/assets/icons/night/clear.svg`;
const NightCloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_1.svg`;
const NightCloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_2.svg`;
const NightCloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_3.svg`;
const NightCloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_4.svg`;
const NightCloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_5.svg`;
const NightCloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_6.svg`;
const NightCirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_1.svg`;
const NightCirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_2.svg`;
const NightCirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_3.svg`;
const NightCirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_4.svg`;
const NightRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_1.svg`;
const NightRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_2.svg`;
const NightRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_3.svg`;
const NightRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_4.svg`;
const NightRainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_1.svg`;
const NightRainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_2.svg`;
const NightRainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_3.svg`;
const NightRainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_4.svg`;
const NightRainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_1.svg`;
const NightRainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_2.svg`;
const NightRainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_3.svg`;
const NightRainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_1.svg`;
const NightRainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_2.svg`;
const NightRainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_3.svg`;
const NightRainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_4.svg`;
const NightIceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_1.svg`;
const NightIceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_2.svg`;
const NightIceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_3.svg`;
const NightIceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_4.svg`;
const NightSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_1.svg`;
const NightSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_2.svg`;
const NightSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_3.svg`;
const NightSnow4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_4.svg`;
const NightSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_1.svg`;
const NightSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_2.svg`;
const NightSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_3.svg`;
const NightSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_4.svg`;
const NightStorm1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_1.svg`;
const NightStorm2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_2.svg`;
const NightStorm3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_3.svg`;
const NightStormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_1.svg`;
const NightStormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_2.svg`;
const NightStormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_3.svg`;
const NightStormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_4.svg`;

// Tableaux séparés pour les icônes jour et nuit
const dayIcons = [
  ClearIcon,
  Cloudy1Icon,
  Cloudy2Icon,
  Cloudy3Icon,
  Cloudy4Icon,
  Cloudy5Icon,
  Cloudy6Icon,
  Cirrus1Icon,
  Cirrus2Icon,
  Cirrus3Icon,
  Cirrus4Icon,
  Rain1Icon,
  Rain2Icon,
  Rain3Icon,
  Rain4Icon,
  RainShower1Icon,
  RainShower2Icon,
  RainShower3Icon,
  RainShower4Icon,
  RainSnow1Icon,
  RainSnow2Icon,
  RainSnow3Icon,
  RainSnowShower1Icon,
  RainSnowShower2Icon,
  RainSnowShower3Icon,
  RainSnowShower4Icon,
  IceRain1Icon,
  IceRain2Icon,
  IceRain3Icon,
  IceRain4Icon,
  Snow1Icon,
  Snow2Icon,
  Snow3Icon,
  Snow4Icon,
  SnowShower1Icon,
  SnowShower2Icon,
  SnowShower3Icon,
  SnowShower4Icon,
  Storm1Icon,
  Storm2Icon,
  Storm3Icon,
  StormShower1Icon,
  StormShower2Icon,
  StormShower3Icon,
  StormShower4Icon,
];

const nightIcons = [
  NightClearIcon,
  NightCloudy1Icon,
  NightCloudy2Icon,
  NightCloudy3Icon,
  NightCloudy4Icon,
  NightCloudy5Icon,
  NightCloudy6Icon,
  NightCirrus1Icon,
  NightCirrus2Icon,
  NightCirrus3Icon,
  NightCirrus4Icon,
  NightRain1Icon,
  NightRain2Icon,
  NightRain3Icon,
  NightRain4Icon,
  NightRainShower1Icon,
  NightRainShower2Icon,
  NightRainShower3Icon,
  NightRainShower4Icon,
  NightRainSnow1Icon,
  NightRainSnow2Icon,
  NightRainSnow3Icon,
  NightRainSnowShower1Icon,
  NightRainSnowShower2Icon,
  NightRainSnowShower3Icon,
  NightRainSnowShower4Icon,
  NightIceRain1Icon,
  NightIceRain2Icon,
  NightIceRain3Icon,
  NightIceRain4Icon,
  NightSnow1Icon,
  NightSnow2Icon,
  NightSnow3Icon,
  NightSnow4Icon,
  NightSnowShower1Icon,
  NightSnowShower2Icon,
  NightSnowShower3Icon,
  NightSnowShower4Icon,
  NightStorm1Icon,
  NightStorm2Icon,
  NightStorm3Icon,
  NightStormShower1Icon,
  NightStormShower2Icon,
  NightStormShower3Icon,
  NightStormShower4Icon,
];

// Fonction pour arrondir au 100m près (utilisée uniquement pour les données de l'API)
const roundToNearest100 = (value: number): number => {
  return Math.round(value / 100) * 100;
};

// Définir un type pour les périodes valides
type ForecastPeriod = 'morningData' | 'afternoonData' | 'eveningData' | 'nightData';

type ReliabilityType = 'bonne' | 'moyenne' | 'mauvaise';

// On rend icon optionnel
interface PeriodData {
  temperature?: number;
  windGust?: number;
  icon?: string;
}

interface ForecastData {
  morningData?: PeriodData;
  afternoonData?: PeriodData;
  eveningData?: PeriodData;
  nightData?: PeriodData;
  bulletin?: string; 
  MountainBulletin?: string; 
  alertOrange?: boolean;
  alertRed?: boolean;
  reliability?: ReliabilityType;
  sunrise?: string;
  sunset?: string;
  // Les champs Iso0 restent dans la structure pour la partie non expertisée
  iso0morning?: string;
  iso0afternoon?: string;
  iso0evening?: string;
  iso0night?: string;
}

// Fonction pour obtenir la valeur la plus fréquente (mode)
const mode = (arr: any[]) => {
  const counts: { [key: string]: number } = {};
  let maxCount = 0;
  let modeValue = arr[0];

  arr.forEach((val) => {
    counts[val] = (counts[val] || 0) + 1;
    if (counts[val] > maxCount) {
      maxCount = counts[val];
      modeValue = val;
    }
  });

  return modeValue;
};

// Déterminer si le coucher du soleil est avant 20h00
const isSunsetBefore20 = (sunset: string): boolean => {
  const sunsetDate = new Date(sunset);
  const sunsetHours = sunsetDate.getHours();
  const sunsetMinutes = sunsetDate.getMinutes();
  return sunsetHours < 20 || (sunsetHours === 20 && sunsetMinutes === 0);
};

// Fonction pour obtenir les données d'une période
const getPeriodData = (
  data: any[],
  startHour: number,
  endHour: number,
  type: 'morning' | 'afternoon' | 'avg',
  isNight: boolean
): PeriodData => {
  const filtered = data.filter((hourly: any) => {
    const hour = new Date(hourly.forecastStart).getHours();
    return hour >= startHour && hour <= endHour;
  });

  // Valeurs par défaut si aucune donnée
  if (filtered.length === 0) {
    return {
      icon: getWeatherIcon(0, isNight),
    };
  }

  const temperatures = filtered.map((hourly: any) => hourly.temperature);
  const windGusts = filtered.map((hourly: any) => hourly.windGust);
  const weatherCodes = filtered.map((hourly: any) => hourly.weatherCode);

  let temperature: number | undefined;
  if (type === 'morning') {
    const morningHour = filtered.find(
      (hourly) => new Date(hourly.forecastStart).getHours() === 9
    );
    temperature = morningHour?.temperature;
  } else if (type === 'afternoon') {
    const afternoonHour = filtered.find(
      (hourly) => new Date(hourly.forecastStart).getHours() === 15
    );
    temperature = afternoonHour?.temperature;
  } else {
    // moyenne
    temperature =
      temperatures.reduce((sum: number, temp: number) => sum + temp, 0) /
      temperatures.length;
  }

  const maxWindGust = Math.max(...windGusts);
  const weatherCode = mode(weatherCodes);
  const icon = getWeatherIcon(weatherCode, isNight);

  return {
    temperature: temperature !== undefined ? Math.round(temperature) : undefined,
    windGust: Math.round(maxWindGust / 5) * 5, // Arrondi à 5 km/h
    icon,
  };
};

// Fonction pour supprimer les champs undefined
const removeUndefined = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined);
  } else if (obj && typeof obj === 'object') {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      const value = removeUndefined(obj[key]);
      if (value !== undefined) {
        newObj[key] = value;
      }
    });
    return newObj;
  } else {
    return obj;
  }
};

const Previsions: React.FC = () => {
  const [weatherData, setWeatherData] = useState<any>(null);
  const [expertForecasts, setExpertForecasts] = useState<Record<string, ForecastData>>({});
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const [editingDay, setEditingDay] = useState<{
    day: string;
    period: ForecastPeriod;
  } | null>(null);

  // Nouveaux états pour le loader et les messages
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  // Nouveaux états pour le décalage manuel
  const [shiftLoading, setShiftLoading] = useState<boolean>(false);
  const [shiftMessage, setShiftMessage] = useState<string | null>(null);
  const [shiftMessageType, setShiftMessageType] = useState<'success' | 'error' | null>(null);

  // Nouveaux états pour le "Mode auto"
  const [vacationModeLocal, setVacationModeLocal] = useState<boolean>(false);
  const [vacationModeInput, setVacationModeInput] = useState<boolean>(false);

  // Ref pour éviter la réinitialisation multiple lors du mode auto
  const hasVacationResetRef = useRef<boolean>(false);

  // ** Ajout d'une nouvelle référence pour la réinitialisation J9-J13 **
  const hasJ9J13ResetRef = useRef<boolean>(false);

  const sidePanelRef = useRef<HTMLDivElement>(null);

  /**
   * Définition de la fonction getFreshDayDataFromAPI
   * en useCallback pour qu'elle soit stable, 
   * et n'invalide plus les autres useCallback.
   */
  const getFreshDayDataFromAPI = useCallback(
    (dayNumber: string, reliability?: ReliabilityType): ForecastData => {
      if (!weatherData || !weatherData.forecastDaily || !weatherData.forecastDaily.days) {
        throw new Error("Données météo introuvables.");
      }

      const dayIndex = parseInt(dayNumber.substring(1), 10);
      const apiDay = weatherData.forecastDaily.days[dayIndex];

      if (!apiDay) {
        throw new Error(`Aucune donnée disponible pour le jour ${dayNumber}.`);
      }

      // Intervalle 00:00 -> 23:59
      const dayDate = new Date(apiDay.forecastStart);
      dayDate.setHours(0, 0, 0, 0);
      const dayStart = dayDate.getTime();
      const dayEnd = dayStart + 24 * 60 * 60 * 1000 - 1;

      const hoursForDay = weatherData.forecastHourly.hours.filter((hour: any) => {
        const forecastTime = new Date(hour.forecastStart).getTime();
        return forecastTime >= dayStart && forecastTime <= dayEnd;
      });

      if (!hoursForDay || hoursForDay.length === 0) {
        throw new Error(`Aucune donnée horaire disponible pour le jour ${dayNumber}.`);
      }

      // Récupération iso0morning, iso0afternoon, iso0evening
      const iso0morningRaw = hoursForDay
        .find((hour: any) => new Date(hour.forecastStart).getHours() === 9)
        ?.freezing_level_height;
      const iso0morning =
        iso0morningRaw !== undefined
          ? roundToNearest100(iso0morningRaw).toString()
          : undefined;

      const iso0afternoonRaw = hoursForDay
        .find((hour: any) => new Date(hour.forecastStart).getHours() === 15)
        ?.freezing_level_height;
      const iso0afternoon =
        iso0afternoonRaw !== undefined
          ? roundToNearest100(iso0afternoonRaw).toString()
          : undefined;

      const iso0eveningRaw = hoursForDay
        .find((hour: any) => new Date(hour.forecastStart).getHours() === 21)
        ?.freezing_level_height;
      const iso0evening =
        iso0eveningRaw !== undefined
          ? roundToNearest100(iso0eveningRaw).toString()
          : undefined;

      // Pour iso0night : on prend 3h du matin du lendemain
      const nextDayIndex = dayIndex + 1;
      let iso0night: string | undefined;
      if (weatherData.forecastDaily.days[nextDayIndex]) {
        const nextApiDay = weatherData.forecastDaily.days[nextDayIndex];
        const nextDayDate = new Date(nextApiDay.forecastStart);
        nextDayDate.setHours(0, 0, 0, 0);
        const dayStartNext = nextDayDate.getTime();
        const dayEndNext = dayStartNext + 6 * 60 * 60 * 1000 - 1; // 00:00 -> 05:59
        const hoursForNextDay = weatherData.forecastHourly.hours.filter((hour: any) => {
          const forecastTime = new Date(hour.forecastStart).getTime();
          return forecastTime >= dayStartNext && forecastTime <= dayEndNext;
        });
        const iso0nightRaw = hoursForNextDay
          .find((hour: any) => new Date(hour.forecastStart).getHours() === 3)
          ?.freezing_level_height;
        iso0night =
          iso0nightRaw !== undefined
            ? roundToNearest100(iso0nightRaw).toString()
            : undefined;
      }

      // Déterminer si le coucher du soleil est avant 20h00
      const isEveningNight = isSunsetBefore20(apiDay.sunset);

      const newExpertForecast: ForecastData = {
        morningData: getPeriodData(hoursForDay, 6, 11, 'morning', false),
        afternoonData: getPeriodData(hoursForDay, 12, 17, 'afternoon', false),
        eveningData: getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight),
        nightData: getPeriodData(
          weatherData.forecastHourly.hours.filter((hour: any) => {
            const forecastTime = new Date(hour.forecastStart).getTime();
            const nextDDate = new Date(apiDay.forecastStart);
            nextDDate.setDate(nextDDate.getDate() + 1);
            nextDDate.setHours(0, 0, 0, 0);
            const dayStartN = nextDDate.getTime();
            const dayEndN = dayStartN + 6 * 60 * 60 * 1000 - 1; // 00:00 -> 05:59
            return forecastTime >= dayStartN && forecastTime <= dayEndN;
          }),
          0,
          5,
          'avg',
          true
        ),
        sunrise: apiDay.sunrise,
        sunset: apiDay.sunset,
        iso0morning,
        iso0afternoon,
        iso0evening,
        iso0night,
        // Ajout de la fiabilité si elle est fournie
        ...(reliability && { reliability }),
        bulletin: '',
        MountainBulletin: '',
      };

      return newExpertForecast;
    },
    [weatherData]
  );

  /**
   * 2) Réinitialisation d'une journée (resetExpertForecastForDay)
   * Resynchronise toutes les données (Temp + Rafales + icônes + iso0...) avec l'API
   */
  const resetExpertForecastForDay = useCallback(
    (dayNumber: string, reliability?: ReliabilityType) => {
      try {
        const newExpertForecast = getFreshDayDataFromAPI(dayNumber, reliability);

        setExpertForecasts((prevForecasts) => {
          const dayKey = `${dayNumber}-expert`;
          const existingDayData = prevForecasts[dayKey] || {};

          return {
            ...prevForecasts,
            [dayKey]: {
              ...existingDayData,
              morningData: newExpertForecast.morningData,
              afternoonData: newExpertForecast.afternoonData,
              eveningData: newExpertForecast.eveningData,
              nightData: newExpertForecast.nightData,
              sunrise: newExpertForecast.sunrise,
              sunset: newExpertForecast.sunset,
              // Les champs Iso0 sont toujours sauvegardés mais seront masqués en mode expert
              iso0morning: newExpertForecast.iso0morning,
              iso0afternoon: newExpertForecast.iso0afternoon,
              iso0evening: newExpertForecast.iso0evening,
              iso0night: newExpertForecast.iso0night,
              ...(reliability && { reliability }),
              bulletin: existingDayData.bulletin || '',
              MountainBulletin: existingDayData.MountainBulletin || '',
            },
          };
        });
      } catch (error: any) {
        console.error(error);
        setMessage(error.message || 'Erreur lors de la réinitialisation');
        setMessageType('error');
      }
    },
    [getFreshDayDataFromAPI]
  );

  /**
   * Nouveau 1 : Réinitialiser UNIQUEMENT les rafales
   */
  const resetWindGustForDay = useCallback(
    (dayNumber: string) => {
      try {
        const newExpertForecast = getFreshDayDataFromAPI(dayNumber);

        setExpertForecasts((prevForecasts) => {
          const dayKey = `${dayNumber}-expert`;
          const existingDayData = prevForecasts[dayKey] || {};

          const updatedMorning = {
            ...existingDayData.morningData,
            windGust: newExpertForecast.morningData?.windGust,
          };
          const updatedAfternoon = {
            ...existingDayData.afternoonData,
            windGust: newExpertForecast.afternoonData?.windGust,
          };
          const updatedEvening = {
            ...existingDayData.eveningData,
            windGust: newExpertForecast.eveningData?.windGust,
          };
          const updatedNight = {
            ...existingDayData.nightData,
            windGust: newExpertForecast.nightData?.windGust,
          };

          return {
            ...prevForecasts,
            [dayKey]: {
              ...existingDayData,
              morningData: updatedMorning,
              afternoonData: updatedAfternoon,
              eveningData: updatedEvening,
              nightData: updatedNight,
            },
          };
        });
      } catch (error: any) {
        console.error(error);
        setMessage(error.message || 'Erreur lors de la réinitialisation vent');
        setMessageType('error');
      }
    },
    [getFreshDayDataFromAPI]
  );

  /**
   * Nouveau 2 : Réinitialiser UNIQUEMENT les températures
   */
  const resetTemperatureForDay = useCallback(
    (dayNumber: string) => {
      try {
        const newExpertForecast = getFreshDayDataFromAPI(dayNumber);

        setExpertForecasts((prevForecasts) => {
          const dayKey = `${dayNumber}-expert`;
          const existingDayData = prevForecasts[dayKey] || {};

          const updatedMorning = {
            ...existingDayData.morningData,
            temperature: newExpertForecast.morningData?.temperature,
          };
          const updatedAfternoon = {
            ...existingDayData.afternoonData,
            temperature: newExpertForecast.afternoonData?.temperature,
          };
          const updatedEvening = {
            ...existingDayData.eveningData,
            temperature: newExpertForecast.eveningData?.temperature,
          };
          const updatedNight = {
            ...existingDayData.nightData,
            temperature: newExpertForecast.nightData?.temperature,
          };

          return {
            ...prevForecasts,
            [dayKey]: {
              ...existingDayData,
              morningData: updatedMorning,
              afternoonData: updatedAfternoon,
              eveningData: updatedEvening,
              nightData: updatedNight,
            },
          };
        });
      } catch (error: any) {
        console.error(error);
        setMessage(error.message || 'Erreur lors de la réinitialisation temp');
        setMessageType('error');
      }
    },
    [getFreshDayDataFromAPI]
  );

  /**
   * Sauvegarde (saveForecasts) - Extraction MountainBulletin
   */
  const extractMountainBulletins = useCallback(() => {
    const mountainData: Record<string, Partial<ForecastData>> = {};
    for (const [key, forecast] of Object.entries(expertForecasts)) {
      if (forecast?.MountainBulletin !== undefined) {
        mountainData[key] = { MountainBulletin: forecast.MountainBulletin };
      }
    }
    return mountainData;
  }, [expertForecasts]);

  const removeMountainBulletinsFromMain = useCallback(
    (forecasts: Record<string, ForecastData>) => {
      const newForecasts = { ...forecasts };
      for (const [key, forecast] of Object.entries(newForecasts)) {
        if (forecast?.MountainBulletin !== undefined) {
          const { MountainBulletin, ...rest } = forecast;
          newForecasts[key] = rest;
        }
      }
      return newForecasts;
    },
    []
  );

  const saveForecasts = useCallback(
    async (shouldReload: boolean = false) => {
      setLoading(true);
      setMessage(null);
      setMessageType(null);
      try {
        // On fusionne toutes les prévisions et on y ajoute notre mode auto
        const allForecasts: Record<string, any> = {
          ...expertForecasts,
          vacationMode: vacationModeInput,
        };

        // On récupère uniquement les MountainBulletins
        const mountainBulletins = extractMountainBulletins();
        // On retire les MountainBulletins du corpus principal
        const allForecastsWithoutMountain = removeMountainBulletinsFromMain(allForecasts);

        // Nettoyage
        const cleanedForecasts = removeUndefined(allForecastsWithoutMountain);
        // On sauvegarde les prévisions « classiques »
        await setDoc(doc(db, 'forecasts', 'expertForecasts'), cleanedForecasts);

        // On sauvegarde maintenant le MountainBulletin
        const cleanedMountain = removeUndefined(mountainBulletins);
        if (Object.keys(cleanedMountain).length > 0) {
          await setDoc(
            doc(db, 'forecasts', 'expertForecastsMountainDefault1700'),
            cleanedMountain,
            { merge: true }
          );
        }

        setMessage('Prévisions mises à jour avec succès !');
        setMessageType('success');

        setVacationModeLocal(vacationModeInput);

        if (shouldReload && vacationModeInput) {
          // window.location.reload();
        }
      } catch (error) {
        console.error('Erreur lors de la sauvegarde des prévisions:', error);
        setMessage('Erreur lors de la mise à jour des prévisions.');
        setMessageType('error');
      } finally {
        setLoading(false);
      }
    },
    [
      expertForecasts,
      vacationModeInput,
      extractMountainBulletins,
      removeMountainBulletinsFromMain
    ]
  );

  // Réinitialiser toutes les prévisions en mode auto
  const resetAllForecastsForVacationMode = useCallback(async () => {
    if (!weatherData) return;

    setLoading(true);
    setMessage(null);
    setMessageType(null);

    try {
      const daysToReset = Array.from({ length: 14 }, (_, i) => `J${i}`);
      for (let i = 0; i < daysToReset.length; i++) {
        const dayNumber = daysToReset[i];
        let reliability: ReliabilityType;
        if (i <= 3) reliability = 'bonne';
        else if (i <= 5) reliability = 'moyenne';
        else reliability = 'mauvaise';

        resetExpertForecastForDay(dayNumber, reliability);
      }

      // On fusionne tout
      const allForecasts: Record<string, any> = {
        ...expertForecasts,
        vacationMode: true,
      };

      const cleanedForecasts = removeUndefined(allForecasts);
      await setDoc(doc(db, 'forecasts', 'expertForecasts'), cleanedForecasts);

      setMessage('Prévisions réinitialisées en mode auto avec succès !');
      setMessageType('success');

      setVacationModeLocal(true);
      hasVacationResetRef.current = true;
    } catch (error) {
      console.error('Erreur lors de la réinitialisation des prévisions en mode auto:', error);
      setMessage('Erreur lors de la réinitialisation des prévisions en mode auto.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  }, [weatherData, resetExpertForecastForDay, expertForecasts]);

  // Gérer le clic en dehors du panel
  const handleClickOutside = (event: MouseEvent) => {
    if (sidePanelRef.current && !sidePanelRef.current.contains(event.target as Node)) {
      setSidePanelOpen(false);
    }
  };

  useEffect(() => {
    if (sidePanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidePanelOpen]);

  // Masquer le message principal après 5s
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Masquer le message de décalage après 5s
  useEffect(() => {
    if (shiftMessage) {
      const timer = setTimeout(() => {
        setShiftMessage(null);
        setShiftMessageType(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [shiftMessage]);

  // Récupération des données météo
  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          'https://api.open-meteo.com/v1/forecast?latitude=45.9088&longitude=6.1257&hourly=temperature_2m,freezing_level_height,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
        );

        const hasFreezingLevelHeight =
          response.data.hourly.freezing_level_height !== undefined;

        if (!hasFreezingLevelHeight) {
          console.warn(
            "Le champ freezing_level_height n'est pas présent dans la réponse de l'API."
          );
        }

        const transformedData = {
          forecastDaily: {
            days: response.data.daily.sunrise.map((sunrise: string, index: number) => ({
              forecastStart: response.data.daily.sunrise[index],
              forecastEnd: response.data.daily.sunset[index],
              sunrise: response.data.daily.sunrise[index],
              sunset: response.data.daily.sunset[index],
            })),
          },
          forecastHourly: {
            hours: response.data.hourly.time.map((time: string, index: number) => ({
              forecastStart: time,
              temperature: response.data.hourly.temperature_2m[index],
              freezing_level_height: hasFreezingLevelHeight
                ? roundToNearest100(response.data.hourly.freezing_level_height[index])
                : undefined,
              weatherCode: response.data.hourly.weather_code[index],
              windGust: response.data.hourly.wind_gusts_10m[index],
              is_day: response.data.hourly.is_day[index],
            })),
          },
        };
        setWeatherData(transformedData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données météo:', error);
        setMessage('Erreur lors de la récupération des données météo.');
        setMessageType('error');
      }
    };
    fetchWeatherData();
  }, []);

  // Récupération des prévisions sauvegardées
  useEffect(() => {
    const fetchSavedForecasts = async () => {
      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data() as Record<string, any>;

        if (typeof data.vacationMode === 'boolean') {
          setVacationModeLocal(data.vacationMode);
          setVacationModeInput(data.vacationMode);
          delete data.vacationMode;
        }

        setExpertForecasts(data as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
      }
    };
    fetchSavedForecasts();
  }, []);

  // Récupération des bulletins montagne
  useEffect(() => {
    const fetchMountainForecasts = async () => {
      try {
        const docRef = doc(db, 'forecasts', 'expertForecastsMountainDefault1700');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const mountainData = docSnap.data() as Record<string, { MountainBulletin?: string }>;
          setExpertForecasts((prevForecasts) => {
            const updatedForecasts = { ...prevForecasts };
            for (const [key, value] of Object.entries(mountainData)) {
              if (!updatedForecasts[key]) {
                updatedForecasts[key] = {} as ForecastData;
              }
              updatedForecasts[key].MountainBulletin = value.MountainBulletin || '';
            }
            return updatedForecasts;
          });
        } else {
          console.log("Aucun bulletin montagne trouvé dans 'expertForecastsMountainDefault1700' !");
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des bulletins montagne :', error);
        setMessage('Erreur lors de la récupération des bulletins montagne.');
        setMessageType('error');
      }
    };
    fetchMountainForecasts();
  }, []);

  // Réinitialiser J9-J13
  useEffect(() => {
    if (
      weatherData &&
      Object.keys(expertForecasts).length > 0 &&
      !hasJ9J13ResetRef.current
    ) {
      const daysToReset = ['J9', 'J10', 'J11', 'J12', 'J13'];
      daysToReset.forEach((dayNumber) => {
        resetExpertForecastForDay(dayNumber, 'mauvaise');
      });
      saveForecasts(); 
      hasJ9J13ResetRef.current = true;
    }
  }, [weatherData, expertForecasts, resetExpertForecastForDay, saveForecasts]);

  // Réinitialiser en mode auto lors du chargement
  useEffect(() => {
    if (vacationModeLocal && weatherData && !hasVacationResetRef.current) {
      resetAllForecastsForVacationMode();
    }
  }, [vacationModeLocal, weatherData, resetAllForecastsForVacationMode]);

  // Mise à jour d'une prévision pour une période
  const updateExpertForecastForPeriod = (
    dayNumber: string,
    period: ForecastPeriod,
    updatedData: PeriodData
  ) => {
    setExpertForecasts((prevForecasts) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prevForecasts[dayKey] || {
        morningData: { icon: getWeatherIcon(0, false) },
        afternoonData: { icon: getWeatherIcon(0, false) },
        eveningData: { icon: getWeatherIcon(0, false) },
        nightData: { icon: getWeatherIcon(0, false) },
        bulletin: '',
        MountainBulletin: '',
        alertOrange: false,
        alertRed: false,
        reliability: 'bonne',
        sunrise: '',
        sunset: '',
        iso0morning: undefined,
        iso0afternoon: undefined,
        iso0evening: undefined,
        iso0night: undefined,
      };

      return {
        ...prevForecasts,
        [dayKey]: {
          ...existingDayData,
          [period]: updatedData,
        },
      };
    });
  };

  // La fonction updateExpertForecastForIso0 a été supprimée puisque les champs Iso0 ne sont plus éditables en mode expert.

  const openSidePanel = (dayNumber: string, period: ForecastPeriod) => {
    setEditingDay({ day: dayNumber, period });
    setSidePanelOpen(true);
  };

  const handleManualShift = async () => {
    setShiftLoading(true);
    setShiftMessage(null);
    setShiftMessageType(null);

    try {
      await axios.get(
        'https://us-central1-annecymeteo-49ea3.cloudfunctions.net/shiftForecastsManual'
      );
      setShiftMessage('Décalage des prévisions effectué avec succès.');
      setShiftMessageType('success');

      const docRef = doc(db, 'forecasts', 'expertForecasts');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
      } else {
        console.log('Aucune prévision sauvegardée trouvée!');
        setShiftMessage('Aucune prévision sauvegardée trouvée.');
        setShiftMessageType('error');
      }
    } catch (error) {
      console.error('Erreur lors du décalage manuel des prévisions:', error);
      setShiftMessage('Erreur lors du décalage des prévisions.');
      setShiftMessageType('error');
    } finally {
      setShiftLoading(false);
    }
  };

  if (!weatherData) {
    return <div>Chargement des données météo...</div>;
  }

  const { forecastDaily, forecastHourly } = weatherData;
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const filteredDays = forecastDaily.days.filter((day: any) => {
    const dayDate = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    return dayDate >= currentDate;
  });

  const startDate = new Date(filteredDays[0].forecastStart);
  startDate.setHours(0, 0, 0, 0);
  const fullDays = [...filteredDays];

  while (fullDays.length < 14) {
    const lastDay = fullDays[fullDays.length - 1];
    const nextDayDate = new Date(lastDay.forecastStart);
    nextDayDate.setDate(nextDayDate.getDate() + 1);
    nextDayDate.setHours(0, 0, 0, 0);
    const nextDay = {
      forecastStart: nextDayDate.toISOString(),
      forecastEnd: nextDayDate.toISOString(),
      sunrise: nextDayDate.toISOString(),
      sunset: nextDayDate.toISOString(),
    };
    fullDays.push(nextDay);
  }

  const renderDayPart = (
    label: string,
    data: PeriodData | undefined,
    isNight: boolean,
    isExpertised: boolean,
    onIconClick?: () => void,
    onDataChange?: (updatedData: PeriodData) => void
  ) => {
    if (!data) {
      return (
        <div className="day-part" key={label}>
          <h4>{label}</h4>
          <p>Données non disponibles</p>
        </div>
      );
    }

    const icon = data.icon || getWeatherIcon(0, isNight);
    const roundedTemperature =
      data.temperature !== undefined ? `${Math.round(data.temperature)}°` : 'N/A';
    const roundedWindGust =
      data.windGust !== undefined ? `${Math.round(data.windGust)}` : 'N/A';

    return (
      <div className="day-part" key={label}>
        <h4>{label}</h4>
        <img
          src={icon}
          alt="weather-icon"
          className="weather-icon"
          onClick={onIconClick}
        />

        {isExpertised && (
          <>
            <div className="input-label-group">
              <label htmlFor={`temperature-${label}`}>Temp</label>
              <input
                id={`temperature-${label}`}
                type="number"
                value={data.temperature !== undefined ? data.temperature : ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === '') {
                    onDataChange && onDataChange({ ...data, temperature: undefined });
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      const newTemp = Math.round(parsedValue);
                      onDataChange && onDataChange({ ...data, temperature: newTemp });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            <div className="input-label-group">
              <label htmlFor={`windGust-${label}`}>Rafales</label>
              <input
                id={`windGust-${label}`}
                type="number"
                value={data.windGust !== undefined ? data.windGust : ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === '') {
                    onDataChange && onDataChange({ ...data, windGust: undefined });
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      onDataChange &&
                        onDataChange({ ...data, windGust: parsedValue });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>
          </>
        )}
        {!isExpertised && (
          <>
            <p>{roundedTemperature}</p>
            <p>
              Rafales: <br /> {roundedWindGust !== 'N/A' ? `${roundedWindGust} km/h` : 'N/A'}
            </p>
          </>
        )}
      </div>
    );
  };

  const renderDay = (
    day: any,
    index: number,
    hourlyData: any[],
    startDate: Date,
    isExpertised: boolean,
    expertForecasts: Record<string, ForecastData>,
    resetExpertForecastForDay: (dayNumber: string, reliability?: ReliabilityType) => void,
    openSidePanel: (dayNumber: string, period: ForecastPeriod) => void,
    nextDayNightHours: any[]
  ) => {
    const dayStart = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    const dayEnd = new Date(day.forecastStart).setHours(23, 59, 59, 999);
    const hoursForDay = hourlyData.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });

    if (!hoursForDay || hoursForDay.length === 0) {
      return (
        <div
          key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`}
          className="day-block"
        >
          <h3>
            Jour {index + 1} - {new Date(day.forecastStart).toLocaleDateString()}
          </h3>
          <p>Données horaires non disponibles pour ce jour.</p>
        </div>
      );
    }

    // iso0*
    const iso0morningRaw = hoursForDay
      .find((hour: any) => new Date(hour.forecastStart).getHours() === 9)
      ?.freezing_level_height;
    const iso0morning =
      iso0morningRaw !== undefined ? roundToNearest100(iso0morningRaw).toString() : undefined;

    const iso0afternoonRaw = hoursForDay
      .find((hour: any) => new Date(hour.forecastStart).getHours() === 15)
      ?.freezing_level_height;
    const iso0afternoon =
      iso0afternoonRaw !== undefined ? roundToNearest100(iso0afternoonRaw).toString() : undefined;

    const iso0eveningRaw = hoursForDay
      .find((hour: any) => new Date(hour.forecastStart).getHours() === 21)
      ?.freezing_level_height;
    const iso0evening =
      iso0eveningRaw !== undefined ? roundToNearest100(iso0eveningRaw).toString() : undefined;

    // iso0night => 3h du lendemain
    let iso0night: string | undefined;
    if (fullDays[index + 1]) {
      const nextApiDay = fullDays[index + 1];
      const nextDayDate = new Date(nextApiDay.forecastStart);
      nextDayDate.setHours(0, 0, 0, 0);
      const nextDayStart = nextDayDate.getTime();
      const nextDayEnd = nextDayStart + 6 * 60 * 60 * 1000 - 1;
      const nextDayHours = hourlyData.filter((hour: any) => {
        const forecastTime = new Date(hour.forecastStart).getTime();
        return forecastTime >= nextDayStart && forecastTime <= nextDayEnd;
      });
      const iso0nightRaw = nextDayHours
        .find((hour: any) => new Date(hour.forecastStart).getHours() === 3)
        ?.freezing_level_height;
      iso0night =
        iso0nightRaw !== undefined ? roundToNearest100(iso0nightRaw).toString() : undefined;
    }

    const morningData = getPeriodData(hoursForDay, 6, 11, 'morning', false);
    const afternoonData = getPeriodData(hoursForDay, 12, 17, 'afternoon', false);
    const isEveningNight = isSunsetBefore20(day.sunset);
    const eveningData = getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight);

    // Nuit (jour suivant 0h-5h59)
    const nightData =
      nextDayNightHours.length > 0
        ? getPeriodData(nextDayNightHours, 0, 5, 'avg', true)
        : {
            temperature: undefined,
            windGust: undefined,
            icon: getWeatherIcon(0, true),
          };

    const daysDifference = Math.floor(
      (new Date(day.forecastStart).getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const dayNumber = `J${daysDifference}`;
    const forecastKey = `${dayNumber}-expert`;
    const expertData = expertForecasts[forecastKey] || {};

    const forecastData: ForecastData = {
      morningData: isExpertised ? { ...morningData, ...expertData.morningData } : morningData,
      afternoonData: isExpertised
        ? { ...afternoonData, ...expertData.afternoonData }
        : afternoonData,
      eveningData: isExpertised ? { ...eveningData, ...expertData.eveningData } : eveningData,
      nightData: isExpertised ? { ...nightData, ...expertData.nightData } : nightData,
      bulletin: isExpertised ? expertData.bulletin || '' : '',
      MountainBulletin: isExpertised ? expertData.MountainBulletin || '' : '',
      alertOrange: isExpertised ? expertData.alertOrange || false : false,
      alertRed: isExpertised ? expertData.alertRed || false : false,
      reliability: isExpertised ? expertData.reliability || 'bonne' : undefined,
      sunrise: expertData.sunrise || day.sunrise,
      sunset: expertData.sunset || day.sunset,
      // Pour les prévisions expertisées, les champs Iso0 sont mis à undefined afin de ne pas être affichés.
      iso0morning: !isExpertised ? (expertData.iso0morning || iso0morning) : undefined,
      iso0afternoon: !isExpertised ? (expertData.iso0afternoon || iso0afternoon) : undefined,
      iso0evening: !isExpertised ? (expertData.iso0evening || iso0evening) : undefined,
      iso0night: !isExpertised ? (expertData.iso0night || iso0night) : undefined,
    };

    return (
      <div
        key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`}
        className={`day-block ${isExpertised ? 'expert-block' : ''} ${
          forecastData.alertOrange ? 'alert-orange' : ''
        } ${forecastData.alertRed ? 'alert-red' : ''}`}
      >
        <h3>
          {dayNumber} -{' '}
          {new Date(day.forecastStart).toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h3>

        {isExpertised && (
          <div className="alert-switches">
            <label className="switch switch-orange">
              <input
                type="checkbox"
                checked={forecastData.alertOrange || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {};
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertOrange: !forecastData.alertOrange,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>

            <label className="switch switch-red">
              <input
                type="checkbox"
                checked={forecastData.alertRed || false}
                onChange={() => {
                  setExpertForecasts((prevForecasts) => {
                    const dayKey = `${dayNumber}-expert`;
                    const existingDayData = prevForecasts[dayKey] || {};
                    return {
                      ...prevForecasts,
                      [dayKey]: {
                        ...existingDayData,
                        alertRed: !forecastData.alertRed,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>

            <div className="reliability-selector">
              <label>
                Fiabilité :
                <select
                  value={forecastData.reliability || 'bonne'}
                  onChange={(e) => {
                    const selectedReliability = e.target.value as ReliabilityType;
                    setExpertForecasts((prevForecasts) => {
                      const dayKey = `${dayNumber}-expert`;
                      const existingDayData = prevForecasts[dayKey] || {};
                      return {
                        ...prevForecasts,
                        [dayKey]: {
                          ...existingDayData,
                          reliability: selectedReliability,
                        },
                      };
                    });
                  }}
                >
                  <option value="bonne">Bonne</option>
                  <option value="moyenne">Moyenne</option>
                  <option value="mauvaise">Mauvaise</option>
                </select>
              </label>
            </div>
          </div>
        )}

        <div className="prevision">
          {renderDayPart(
            'Matin',
            forecastData.morningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'morningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'morningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Aprèm',
            forecastData.afternoonData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'afternoonData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'afternoonData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Soirée',
            forecastData.eveningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'eveningData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'eveningData', updatedData)
              : undefined
          )}

          {renderDayPart(
            'Nuit',
            forecastData.nightData,
            true,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'nightData') : undefined,
            isExpertised
              ? (updatedData: PeriodData) =>
                  updateExpertForecastForPeriod(dayNumber, 'nightData', updatedData)
              : undefined
          )}

          {/* Affichage des champs Iso0 uniquement en mode non expertisé */}
          {!isExpertised && (
            <div className="iso0-field">
              <p>
                {forecastData.iso0morning !== undefined &&
                forecastData.iso0afternoon !== undefined &&
                forecastData.iso0evening !== undefined &&
                forecastData.iso0night !== undefined
                  ? `${forecastData.iso0morning}m / ${forecastData.iso0afternoon}m / ${forecastData.iso0evening}m / ${forecastData.iso0night}m`
                  : 'N/A'}
              </p>
            </div>
          )}
        </div>

        {isExpertised && (
          <>
            <button onClick={() => resetWindGustForDay(dayNumber)}>
              Réinitialiser vent
            </button>
            <button onClick={() => resetTemperatureForDay(dayNumber)}>
              Réinitialiser temp
            </button>
            <button onClick={() => resetExpertForecastForDay(dayNumber)}>
              Réinitialiser avec API
            </button>

            <textarea
              value={forecastData.bulletin || ''}
              onChange={(e) => {
                const newBulletin = e.target.value;
                setExpertForecasts((prevForecasts) => {
                  const dayKey = `${dayNumber}-expert`;
                  const existingDayData = prevForecasts[dayKey] || {};
                  return {
                    ...prevForecasts,
                    [dayKey]: {
                      ...existingDayData,
                      bulletin: newBulletin,
                    },
                  };
                });
              }}
              placeholder="Écrire le bulletin pour ce jour"
              style={{ overflow: 'hidden', resize: 'none' }}
              className="bulletin-textarea"
            />
            <textarea
              value={forecastData.MountainBulletin || ''}
              onChange={(e) => {
                const newMountainBulletin = e.target.value;
                setExpertForecasts((prevForecasts) => {
                  const dayKey = `${dayNumber}-expert`;
                  const existingDayData = prevForecasts[dayKey] || {};
                  return {
                    ...prevForecasts,
                    [dayKey]: {
                      ...existingDayData,
                      MountainBulletin: newMountainBulletin,
                    },
                  };
                });
              }}
              placeholder="Écrire le bulletin montagne pour ce jour"
              style={{ overflow: 'hidden', resize: 'none', marginTop: '10px' }}
              className="bulletin-textarea"
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="prevision-page">
      <Header />
      <span className="title"> Annecy</span>
      <div className="vacation-mode-switch">
        <label className="switch">
          <input
            type="checkbox"
            checked={vacationModeInput}
            onChange={() => setVacationModeInput((prev) => !prev)}
          />
          <span className="slider round"></span>
        </label>
        <span>Mode auto</span>
      </div>

      <div className="prevision-container">
        {fullDays.map((day: any, index: number) => {
          // Obtenir le lendemain
          const nextDay = fullDays[index + 1];
          const nextDayNightHours = nextDay
            ? forecastHourly.hours.filter((hour: any) => {
                const forecastTime = new Date(hour.forecastStart);
                const nextDayDate = new Date(nextDay.forecastStart);
                return (
                  forecastTime.getFullYear() === nextDayDate.getFullYear() &&
                  forecastTime.getMonth() === nextDayDate.getMonth() &&
                  forecastTime.getDate() === nextDayDate.getDate() &&
                  forecastTime.getHours() >= 0 &&
                  forecastTime.getHours() < 6
                );
              })
            : [];

          return (
            <div key={index} className="combined-day-block">
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                false,
                expertForecasts,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours
              )}
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                true,
                expertForecasts,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours
              )}
            </div>
          );
        })}

        {sidePanelOpen && editingDay && (
          <div ref={sidePanelRef} className="side-panel">
            <h3>Choisir une icône</h3>
            <div className="icon-grid">
              {(() => {
                const { day, period } = editingDay;
                const dayKey = `${day}-expert`;
                const currentForecast = expertForecasts[dayKey] || {};
                let availableIcons: string[] = [];

                if (period === 'morningData' || period === 'afternoonData') {
                  availableIcons = dayIcons;
                } else if (period === 'eveningData') {
                  const sunset =
                    currentForecast.sunset ||
                    weatherData.forecastDaily.days[parseInt(day.substring(1), 10)]
                      .sunset;
                  const isEveningNight = isSunsetBefore20(sunset);
                  availableIcons = isEveningNight ? nightIcons : dayIcons;
                } else if (period === 'nightData') {
                  availableIcons = nightIcons;
                }

                return availableIcons.map((iconPath, idx) => (
                  <img
                    key={idx}
                    src={iconPath}
                    alt={`Icon ${idx}`}
                    className="icon-choice"
                    onClick={() => {
                      setExpertForecasts((prev) => {
                        const existingDayData = prev[dayKey] || {
                          morningData: { icon: getWeatherIcon(0, false) },
                          afternoonData: { icon: getWeatherIcon(0, false) },
                          eveningData: { icon: getWeatherIcon(0, false) },
                          nightData: { icon: getWeatherIcon(0, false) },
                          bulletin: '',
                          MountainBulletin: '',
                          alertOrange: false,
                          alertRed: false,
                          reliability: 'bonne',
                          sunrise: '',
                          sunset: '',
                          iso0morning: undefined,
                          iso0afternoon: undefined,
                          iso0evening: undefined,
                          iso0night: undefined,
                        };
                        const existingPeriodData: PeriodData =
                          existingDayData[period] || {
                            icon: getWeatherIcon(0, false),
                          };

                        const updatedPeriodData: PeriodData = {
                          ...existingPeriodData,
                          icon: iconPath,
                        };

                        return {
                          ...prev,
                          [dayKey]: {
                            ...existingDayData,
                            [period]: updatedPeriodData,
                          },
                        };
                      });
                      setSidePanelOpen(false);
                    }}
                  />
                ));
              })()}
            </div>
          </div>
        )}

        <div className="manual-shift-button">
          <button onClick={handleManualShift} disabled={shiftLoading}>
            {shiftLoading ? <div className="loader"></div> : 'Forcer le décalage des prévisions'}
          </button>
        </div>

        <div className="bandeau-floating-button">
          <button
            className="floating-button"
            onClick={() => saveForecasts(vacationModeInput)}
            disabled={loading}
          >
            {loading ? <div className="loader"></div> : 'Enregistrer'}
          </button>
        </div>

        {shiftMessage && (
          <div className={`message-banner ${shiftMessageType}`}>{shiftMessage}</div>
        )}

        {message && <div className={`message-banner ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default Previsions;